.form-sec {
    &__content {
        padding: 0 0 4.375em 0;

        p {
            padding: 0 4em;

            @media #{$ipadpro} {
                padding: 0 2em;
            }

            @media #{$ipad} {
                padding: 0;
            }
        }
    }
}

.contact {
    .contact-Info-wrapper {
        .contact-Info {
            display: flex;

            .img-wrapper {
                width: 3.125em;
                height: 3.125em;
                border: 1px solid $secondarycol;
                transition: .5s ease-in-out;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0 1.875em 0 0;
                &:hover {
                    background: $secondarycol;
                    transition: .3s ease-in-out;
                    svg {
                        path {
                            fill: $whitecol;
                            transition: .3s ease-in-out;
                        }
                    }
                }
            }

            .contact-Info-detail {
                margin: 0 0 1.875em 0;
                .w-uline{
                    &::after{
                        background: $secondarycol;
                    }
                }
            }
        }
    }

    .social-icon-wrapper {
        display: flex;
        margin: 1.125em 0 0 0;

        .socail-img {
            width: 3.125em;
            height: 3.125em;
            border: 1px solid $secondarycol;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 1.875em 0 0;

            &:hover {
                background: $secondarycol;
                transition: .3s ease-in-out;

                svg {
                    path {
                        fill: $whitecol;
                        transition: .3s ease-in-out;
                    }
                }
            }
        }
    }

    .map {
        border-radius: 5em ;
        overflow: hidden;
        @media #{$mob} {
            margin: 2em 0;
            border-radius: 2em;
        }
        iframe{
            width: 100%;
            height: 100%;
            @media #{$mobL} {
                min-height: 25em;
            }
            @media #{$mob} {
                min-height: 16em;
            }
        }
    }

    &-form {
        .g-recaptcha {
            margin: 0 auto;
            width: auto!important;
        }
    }
}