

.heroBanner {
    .menu {
        &__menuitem {
            &--menulink {
                color: $whitecol;

                &::before {
                    background: $whitecol;
                }
            }
        }
    }

    
}

.inner-heroBanner {
    position: relative;
    height: 100vh;

    @media #{$ipadpro} {
        height: 50em;
    }

    @media #{$mob} {
        height: 70vh;
    }

    .img-wrapper {
        height: 100%;

        img {
            height: 100%;
        }
    }

    .right-patch {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;

        @media #{$ipadpro} {
            display: none;
        }

        img {
            height: 100%;
        }
    }

    &__contentWrapper {
        width: 58.4375em;
        position: absolute;
        background: $whitecol;
        bottom: 0;
        right: 0;
        padding-left: 8em;
        border-top-left-radius: 3em;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media #{$desktop} {
            height: 50%;
        }
        @media #{$ipadpro} {
            width: 50%;
            padding-left: 5em;
        }

        @media #{$mob} {
            width: 100%;
            padding: 2em 2em 0 2em;
        }

        .heading-wrapper {
            max-width: 60%;
        }

        .black-patch {
            @media #{$mob} {
                display: none;
            }
        }
    }

    &.black-convert {
        .inner-heroBanner {
            &__contentWrapper {
                background: $blackcol;
                width: auto;

                @media #{$ipadpro} {
                    width: 50%;
                }

                @media #{$mob} {
                    width: 100%;
                    padding: 2em 2em 0 2em;
                }
            }
        }

        .right-patch {
            svg {
                fill: $blackcol;

                path {
                    fill: $blackcol;
                }
            }
        }

        .black-patch {
            path {
                fill: $primarycol;


            }
        }

        .heading-wrapper {
            color: $whitecol;
        }
    }

    &.blue-convert {
        .inner-heroBanner {
            &__contentWrapper {
                background: $primarycol;

                .h1 {
                    color: $whitecol;
                }
            }
        }
    }

    &.upadated-hero {
        @media #{$ipadpro} {
            height: 40em;
        }

        @media #{$mob} {
            height: 25em;
        }

        .inner-heroBanner {
            &__contentWrapper {
                
                padding-left: 0;
                justify-content: flex-end;
                @media #{$desktop} {
                    height: 60%;
                }
                @media (max-width:1366px){
                    height: auto;
                }
                @media #{$ipadpro} {
                    width: auto;
                }
                @media #{$mob} {
                    justify-content: center;
                    top: 50%;
                    transform: translate(0px, -50%);
                    left: 0;
                }

                .heading-wrapper {
                    max-width: 25%;

                    @media #{$desktop} {
                        min-width: 30em;
                        height: 100%;
                    }

                    @media #{$ipadpro} {
                        max-width: 20%;
                    }

                    @media #{$mob} {
                        max-width: 100%;
                    }
                }
            }
        }


        .img-wrapper {
            width: 50%;
            height: auto;
            border-top-left-radius: 3em;
            overflow: hidden;

            @media #{$mob} {
                display: none;
            }
        }

        .right-patch {
            z-index: 2;
            display: block;

            @media #{$mob} {
                display: none;
            }
        }

        .black-patch {
            position: absolute;
            right: 0;
            bottom: 0;

            @media #{$ipadpro} {
                max-width: 22%;
            }
        }
    }
}