.search-banner {
    .search-box {
        &.open {
            height: 40.625em;
            position: relative;
            display: flex;
            align-items: center;
        }
    }
}

.search-result-sec {
    padding-bottom: 0;

    .col_12 {
        .search-result-content {
            height: 100%;
            border-top: 0.5px solid rgba(17, 24, 32, 0.4);
            padding: 5em 13em 2.25em 0;

            @media #{$ipadpro} {
                padding: 2.25em 3em 2.25em 0;
            }

            @media #{$mobL} {
                padding: 2em 0 2.25em 0;
            }

        }

        &:nth-child(-n + 2) {
            .search-result-content {
                padding: 0 13em 2.25em 0;
                border: 0;
                @media #{$ipadpro} {
                    padding: 0 3em 2.25em 0;
                }
            }
        }

        &:nth-child(2) {
            .search-result-content {
                @media #{$mobL} {
                    border-top: 0.5px solid rgba(102, 102, 102, 0.4);
                    padding: 2em 0 2.25em 0;
                }
            }
        }


    }
}