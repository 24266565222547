.innerBanner {
    position: relative;
    overflow: initial;

    .img-wrapper {
        position: relative;
        object-fit: cover;
        height: 100%;
        height: 34.375em;

        @media #{$ipad} {
            height: 25em;
        }

        @media #{$mob} {
            height: 31.25em;
        }

        &::before {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            background: linear-gradient(180deg, rgba(0, 0, 0, .7) -12.17%, rgba(0, 0, 0, .0001) 84.04%);
        }

        img {
            height: 100%;
            object-fit: cover;
        }
    }

    .content-wrapper {
        position: absolute;
        top: 50%;
        height: auto;
    }
}

.breadcrumb {
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: 9;

    @media #{$ipadpro} {
        position: relative;
        top: auto;
    }

    @media #{$mob} {
        display: none;
    }

    .breadcrumb-wrapper {
        padding: 1.25em 0 0 0;
        display: flex;

        &__list {
            padding: 0 0.9375em;
            text-transform: uppercase;
            line-height: 1;
            border-right: 1px solid $blackcol;

            a {
                color: $paracol;
            }

            &:first-child {
                padding: 0 0.9375em 0 0;
            }

            &:last-child {
                border: none;
                color: $secondarycol;
            }
        }
    }
}


.content-wrapper {
    height: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;

    h2 {
        width: 70%;

        @media #{$ipad} {
            width: 100%;
        }
    }
}


.content-image-section {
    .img-wrapper {
        padding-left: 5.25em;
        height: 32.875em;
        @media #{$ipad} {
            padding-left: 0;
            height: 25.875em;
        }
    }

    &.reverse-content {
        .custom-row {
            @media #{$tabL} {
                flex-direction: row-reverse;

            }
        }

        .img-wrapper {
            padding-right: 5.25em;
            padding-left: 0;

            @media #{$ipad} {
                padding-right: 0;
            }

            @media #{$mob} {
                padding-top: 1em;
            }
        }
    }

    h5 {
        font-family: $basefont;
    }
}

.our-mission {
    .mission-img-wrapper {
        position: relative;
        margin-bottom: 3.5em;

        .big-img {
            position: relative;
            padding-right: 8.125em;

            img {
                width: 100%;
            }

            @media #{$ipadpro} {
                padding-right: 3.5em;
            }

            @media #{$mobL} {
                padding-right: 0;
            }

            .sm-img-wrapper {
                position: absolute;
                bottom: -3.25em;
                right: 2.1875em;
                background: $whitecol;
                padding: 1.875em 1.625em;
                box-shadow: 7px 8px 45px rgba(17, 34, 103, 0.12);
                border-radius: 15px;

                @media #{$ipadproL} {
                    width: 15em;
                }

                @media #{$ipadpro} {
                    width: 8em;
                    right: 0;
                }

                @media #{$mob} {
                    display: none;
                }
            }
        }
    }
}


.our-team {

    .swiper-button-next,
    .swiper-button-prev {
        top: 40%;

        @media #{$ipadpro} {
            top: auto
        }
    }

    .swiper-button-next {
        right: 6.25em #{"/*rtl: 6.25em*/"};

        @media #{$ipadpro} {
            right: calc(50% - 3.5em);
        }
    }

    .swiper-button-prev {
        left: 6.25em #{"/*rtl: 6.25em*/"};

        @media #{$ipadpro} {
            left: calc(50% - 3.5em);
        }
    }

    // .team-slider-wrapper{
    //     @media #{$ipad} {
    //         padding: 0 1em;
    //     }
    // }
}

.gallery {
    .gallery-slider {
        height: 36.549em;

        @media #{$ipad} {
            padding: 0 5em;
        }

        @media #{$mob} {
            padding: 0 2em;
            height: 20em;
            max-height: 20em;
        }
    }

    // @media #{$mob} {
    //     height: 31em;
    // }


    .swiper-wrapper {
        align-items: center;
        .video-wrapper {
            height: 100%;
        }

    }

    .swiper-slide {
        // height: auto;
        // height: 31.25em;
        align-items: center;
        display: flex;
        //  transition: .5s;
        transition: .4s;
        padding-left: 11.3125em;
        padding-right: 11.3125em;
        @media #{$ipad} {
            padding-left: 2.5em;
            padding-right: 2.5em;
        }
        @media #{$mobL} {
            // height: auto;
            // max-height: 20em;
            padding-left: 0.5em;
            padding-right: 0.5em;
        }

        .img-wrapper {
            width: 100%;
            height: 100%;
            position: relative;
            border-radius: 0.9375em;
            overflow: hidden;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .swiper-slide-active {
        // width: 56.25em!important;
        // height: 36.875em;
        @media #{$desktop} {
            padding-left: 1.5625em;
            padding-right: 1.5625em;
        }
        @media #{$mob} {
            // height: auto;
            height: 100%;
            // max-height: 20em;
            padding-left: 0.5em;
            padding-right: 0.5em;
        }

        iframe {
            width: 67.25em;
            height: 38.875em;
            transition: .5s;
            position: absolute;
            z-index: 0;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            // @media #{$mob} {
            //     height: auto;
            //     max-height: 20em;
            // }
        }
    }

    .swiper-button-next,
    .swiper-button-prev {
        @media #{$mob} {
            top: 85%;
        }
    }

    .swiper-button-next {
        right: 26em #{"/*rtl: 26em*/"};
        transform: translateY(50%) rotate(180deg);

        @media #{$ipadpro} {
            right: 12em #{"/*rtl: 12em*/"};
            width: 2.5em;
            height: 2.5em;
        }

        @media #{$ipad} {
            right: 9em #{"/*rtl: 9em*/"};
        }

        @media #{$mobL} {
            top: 55%
        }

        @media #{$mob} {
            right: calc(50% - 3.5em) #{"/*rtl: calc(50% - 3.5em)*/"};
            top: auto;
        }
    }

    .swiper-button-prev {
        left: 26em #{"/*rtl: 26em*/"};
        transform: translateY(50%);

        @media #{$ipadpro} {
            left: 12em #{"/*rtl: 12em*/"};
            width: 2.5em;
            height: 2.5em;
        }

        @media #{$ipad} {
            left: 9em #{"/*left: 9em*/"};
        }

        @media #{$mobL} {
            top: 55%;
        }

        @media #{$mob} {
            left: calc(50% - 3.5em) #{"/*rtl: calc(50% - 3.5em)*/"};
            top: auto;
        }
    }

    .gallery-navigation-wrapper {
        margin-top: 2em;
        @media #{$mob} {
            position: relative;
            height: 2.5em;
        }
    }
}

.team-navigation-wrapper {
    @media #{$ipadpro} {
        margin-top: 2em;
    }

    @media #{$mob} {
        margin-top: 0;
    }
}