.notfound {
    &_text {
        height: 100vh;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        text-align: center;

        .container {
            & > * {
                text-align: center;
            }
        }
    }
}