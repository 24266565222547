.awards {
    &__list {
        display: flex;
        flex-direction: column;
        margin: 0 0 0 6.25em;

        @media #{$ipadpro} {
            margin: 0;
        }
    }

    .award-received {
        display: flex;
        align-items: center;
        padding: 1.875em 0;
        border-bottom: 1px solid #808080;

        @media #{$ipadpro} {
            padding: 1em 0;
        }

        &:last-child {
            border: none;
        }

        &__logo {
            width: 5.625em;
            height: 5.9375em;
            margin: 0 1.875em 0 0;
        }

        .h4 {
            font-family: $basefont;
        }
    }

    .award-content {
        margin: 2.625em 0 0 0;

        @media #{$mob} {
            margin: 0;
        }
    }
}

.news-cards {
    .primary-anchor {
        margin: 2em 0 0 0;
        @media #{$mob} {
            margin: 0;
            justify-content: flex-start;
        }
    }
}

// .news-cards-detail {
//     .content-card {
//         width: calc(33.3333333333% - 1.875em);
//         margin-right: 1.875em;

//         @media #{$ipad} {
//             width: calc(50% - 1.875em);
//         }

//         @media #{$mob} {
//             width: 100%;
//         }

//         .teamCard-content-wrapper {
//             padding: 1.875em .5em 1.875em 0;
//         }
//     }
// }

.reasons {
    &__content-wrapper {}

    .img-wrapper {
        margin: 1.875em 0 3.125em 0;
    }
}

.related-news {
    background: $lightBlueBG;
}

.news-cards-slider {
    padding: 1.25em 0 0 0;

    @media #{$ipad} {
        padding: 0;
    }
}