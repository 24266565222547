.obscure {
    .img-wrapper {
        padding-left: 5.25em;
        display: flex;
        justify-content: flex-end;
        @media #{$mob} {
            padding-left: 0;
        }
    }
}

.pleasure {

       

    .img-wrapper {
        padding-right: 5.25em;
        @media #{$mob} {
            padding-right: 0;
        }

    }
}