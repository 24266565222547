.pet-profile {
    .small-img-slider {
        margin: 1.625em 0 0 0;
    }

    .pet-spacification {

        .spacification {
            padding-left: 1.7em;
            font-size: 1.25em;
            position: relative;

            @media #{$ipad} {
                font-size: 1em;
            }

            &::before {
                content: "";
                position: absolute;
                width: 0.7em;
                height: 0.7em;
                background: url(../svg/list-arrow.svg) no-repeat;
                background-size: contain;
                left: 0;
                top: 0.7em;
                transform: translateY(-50%) #{"/*rtl: translateY(-50%) rotate(180deg)*/"};

                @media #{$mob} {
                    top: 0.5em;
                    transform: translateY(0);
                }
            }
        }
    }

    .features-wrapper {
        margin-top: 1.875em;
        padding: 1.875em;
        background: $graybg;
        border: 0.5px solid #4B7DC4;
        border-radius: 0.625em;

        .features {
            column-count: 2;

            @media #{$mob} {
                column-count: 1;
            }

            &__list {
                padding-left: 1.875em;
                font-size: 1.125em;
                position: relative;

                @media #{$ipad} {
                    font-size: 1em;
                }

                &::before {
                    content: "";
                    position: absolute;
                    width: 0.75em;
                    height: 0.75em;
                    background: url(../svg/black-tick.svg) no-repeat;
                    background-size: contain;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);

                    @media #{$mob} {
                        top: 0.5em;
                        transform: translateY(0);
                    }
                }
            }
        }
    }

    .pet-detials {
        @media #{$ipad} {
            margin-top: 2em;
        }
    }

    .para-wrapper {
        margin-top: 3.125em;
    }
}

.characteristics {
    padding-top: 0;

    &__wrapper {
        padding-top: 0.625em;
        @media #{$tabL} {
            justify-content: space-between;
        }
    }

    .para-heart-wrapper {
        display: flex;
        // padding: 0 8.125em 0 0;
        padding: 0;
        justify-content: space-between;
        width: 100%;
        width: 100%;
        margin-bottom: 2.5em;

        @media #{$ipadpro} {
            padding: 0;
        }

        .heart-para {
            // margin: 0 0 2.5em 0;
            position: relative;
            width: 20%;

            @media #{$ipadpro} {
                width: 30%;
            }

            @media #{$ipad} {
                width: 33%;
            }

            @media #{$mobL} {
                width: 25%;
            }

            @media #{$mob} {
                width: 33%;
            }

            p {
                margin: 0;
            }


        }
    }

    .heart-part-line {
        position: relative;
        width: 40%;
        margin: 0 1em;

        @media #{$ipad} {
            width: 33%;
        }
        @media #{$mobL} {
            width: 40%;
        }
        &::after {
            content: '';
            position: absolute;
            height: 1px;
            width: 100%;
            background: #111820;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    .hearts-wrapper {
        display: flex;
        justify-content: space-between;
        width: 40%;
        align-items: center;

        @media #{$ipad} {
            width: 33%;
        }
        @media #{$mobL} {
            width: 35%;
        }
        .hearts {
            margin: 0 0.3125em;

            &.filled {
                svg {
                    path {
                        fill: $secondarycol;
                    }
                }
            }
        }
    }
}

.video-sec {
    padding-top: 0;

    &__img {
        width: 100%;
        height: 100%;
        position: relative;
        transition: .5s;

        @media #{$mob} {
            height: 25em;
        }

        &::before {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0);
            transition: .5s;
        }

        img {
            width: 100%;
            height: 100%;
            background-size: cover;
        }

        .btn-img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            cursor: pointer;
            width: 6.875em;
            height: 6.875em;

            @media #{$mob} {
                height: 8em;
                width: 8em;
            }

            &:before {
                content: "";
                position: absolute;
                z-index: -1;
                left: 50%;
                top: 50%;
                transform: translateX(-50%) translateY(-50%);
                display: block;
                width: 7.5em;
                height: 7.5em;
                background: $whitecol;
                border-radius: 50%;
                -webkit-animation: pulse-border 1500ms ease-out infinite;
                animation: pulse-border 1500ms ease-out infinite;
            }
        }

        &:hover {
            &::before {
                background-color: rgba(0, 0, 0, 0.35);
                transition: .5s;
            }
        }
    }

    &__video {
        position: relative;
        width: 100%;
        height: 100%;

        iframe {
            position: absolute;
            max-width: 100vw;
            width: 100%;
            height: 100%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
}

.fancybox__content {
    &.video-popup {
        width: 80vw;
        height: 90vh;
        background: $blackcol  !important;

        @media #{$mob} {
            height: 45vh;
        }
    }
}


@keyframes pulse-border {
    0% {
        transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
        opacity: 1;
    }

    100% {
        transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
        opacity: 0;
    }
}

.big-img-slider {
    .swiper-slide {
        height: auto;
        .img-wrapper {
            width: 100%;
            height: 100%;
        }
    }
}